@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=MuseoModerno&display=swap");
@import url("http://fonts.cdnfonts.com/css/clash-display");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@700&display=swap");

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

html {
    line-height: 1.5;
    overflow-x: hidden;
}

body {
    background: #111111;
    color: #fff;
    font-family: "DM Sans", sans-serif;
}

@font-face {
    font-family: CalSans-SemiBold;
    src: url("../public/assets/fonts/CalSans-SemiBold.otf");
}

a {
    color: inherit;
    text-decoration: none;
}

ul,
ol {
    list-style-position: inside;
}
