.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

video {
    width: 100%;
}

.video-wrapper {
    width: 100%;
    max-width: 700px;
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
    border-radius: 10px;
}

.video-wrapper:hover .controls {
    transform: translateY(0%);
}

.controls {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    bottom: 30px;
    padding: 14px;
    width: 90%;
    max-width: 500px;
    background: rgba(17, 17, 17, 0.74);
    backdrop-filter: blur(8px);
    border-radius: 10px;
    transform: translateY(150%);
    transition: all 0.3s ease-in-out;
}

.controls button {
    color: #fff;
    font-size: 30px;
    display: flex;
    align-items: center;
}

.actions button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
}

input[type="range"] {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    height: 4px;
    width: 350px;
}

input[type="range"]::-webkit-slider-thumb {
    cursor: pointer;
    height: 6px;
}

input[type="range"]::-moz-range-progress {
    background: white;
}

.velocity {
    appearance: none;
    background: none;
    color: white;
    outline: none;
    border: none;
    text-align: center;
    font-size: 16px;
}

.velocity option {
    color: #000;
    padding: 20px;
}

.mute-btn {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
}

.mute-btn i {
    background-color: none;
    color: white;
    font-size: 20px;
}
